import React, { useEffect } from "react"
import { graphql } from "gatsby"
import SEO from "components/seo"
import Transition from "components/transition"
import CareersForm from "components/careers/form"
import Overline from "components/overline"
import handleExternalUrls from "utils/handleExternalUrls"

const OpenPosition = ({ data: { wpOpenPosition } }) => {
  if (!wpOpenPosition) return null

  useEffect(() => {
    handleExternalUrls()
  }, [wpOpenPosition]) // Only re-run if wpOpenPosition changes

  const { seo, title, position } = wpOpenPosition

  return (
    <Transition>
      <SEO
        seo={seo}
        bodyAttributes={{
          class: "page--job-listing bg-white",
        }}
      />
      <div className="container md:py-40 py-28">
        <div className="max-w-lg mx-auto mb-14 lg:mb-24 lg:mt-13">
          <Overline className="text-left">Open Position</Overline>
          <h1 className="mb-5 font-serif text-bear">{title}</h1>
          <div className="mb-2 font-semibold uppercase text-squirrel text-iron">
            {position.location}
          </div>
        </div>

        <div className="max-w-lg mx-auto mb-18 md:mb-24 c-rich-text has-asterisk-bullets">
          <div dangerouslySetInnerHTML={{ __html: position.description }}></div>
        </div>

        <div className="max-w-lg mx-auto lg:pr-4">
          <h2 className="font-serif mb-15 text-38px">Apply for this role.</h2>
          <CareersForm jobTitle={title} />
        </div>
      </div>
    </Transition>
  )
}

export const query = graphql`
  query OpenPosition($id: Int!) {
    wpOpenPosition(databaseId: { eq: $id }) {
      ...OpenPositionSEO
      title
      position: openPositionPost {
        location
        description
      }
    }
  }
`

export default OpenPosition
