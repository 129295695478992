const handleExternalUrls = () => {
  document.querySelectorAll("a").forEach(el => {
    if (el.hostname && el.hostname !== location.hostname) {
      if (el.target === "") {
        el.target = "_blank"
      }
      if (el.rel === "") {
        el.rel = "noreferrer noopener"
      }
    }
  })
}

export default handleExternalUrls
