import React, { useState, useMemo, useEffect } from "react"
import { useForm, useField } from "react-form"
import emailValidator from "email-validator"
import FileUpload from "components/contact/file-upload"
import Reward from "react-rewards"
import Spinner from "assets/spinner.svg"

const { getNames } = require("country-list")
const countryList = getNames()

async function sendToServer(values) {
  const encode = data => {
    const formData = new FormData()
    Object.keys(data).forEach(k => {
      formData.append(k, data[k])
    })
    return formData
  }

  try {
    const res = await fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(encode(values)).toString(),
      // body: encode(values),
    })
    return res.status === 200
  } catch (error) {
    console.error(error)
    return false
  }
}

// Autofill Hack - https://codedaily.io/tutorials/69/Animated-Input-Label-with-Chrome-Autofill-Detection-in-React
function handleAutoFill() {}

function ErrorMessage(props) {
  return (
    <div className="c-form__errmsg">
      <div className="c-form__errmsg-inner">{props.error}</div>
    </div>
  )
}

function FieldLabel(props) {
  return (
    <div
      className={`c-form__textlabel ${
        props.isSmall ? "c-form__textlabel--small" : ""
      }`}
    >
      {props.label}
    </div>
  )
}

function NameField(props) {
  const {
    meta: { error },
    getInputProps,
  } = useField("name", {
    validate: val => (!val ? "Oops, looks like you forgot this one." : false),
  })
  const val = getInputProps().value
  const hasValue = val && val.length > 0
  return (
    <>
      <FieldLabel isSmall={hasValue} label={props.label} />
      <input
        {...getInputProps(props)}
        data-small={hasValue}
        onAnimationStart={handleAutoFill}
      />
      {error ? <ErrorMessage error={error} /> : null}
    </>
  )
}

function LocationField(props) {
  const {
    meta: { error },
    getInputProps,
  } = useField("location", {
    validate: val => (!val ? "Oops, looks like you forgot this one." : false),
  })
  const val = getInputProps().value
  const hasValue = val && val.length > 0
  return (
    <>
      <FieldLabel isSmall={hasValue} label={props.label} />
      <input
        {...getInputProps(props)}
        data-small={hasValue}
        onAnimationStart={handleAutoFill}
      />
      {error ? <ErrorMessage error={error} /> : null}
    </>
  )
}

function EmailField(props) {
  const {
    meta: { error },
    getInputProps,
  } = useField("email", {
    validate: async value => {
      if (!value) {
        return "Oops, you forgot your email address."
      }

      if (!emailValidator.validate(value)) {
        return "Please enter a valid email address."
      }
      return false
    },
  })

  const val = getInputProps().value
  const hasValue = val && val.length > 0

  return (
    <>
      <FieldLabel isSmall={hasValue} label={props.label} />
      <input
        {...getInputProps(props)}
        data-small={hasValue}
        onAnimationStart={handleAutoFill}
      />
      {error ? <ErrorMessage error={error} /> : null}
    </>
  )
}

function PortfolioField(props) {
  const file = props.file

  const {
    runValidation,
    meta: { error },
    getInputProps,
  } = useField("portfolio", {
    validate: val => {
      return !val && !file ? "Oops, looks like you forgot this one." : false
    },
  })

  useEffect(() => {
    if (file) {
      runValidation()
    }
  }, [file])

  const val = getInputProps().value
  const hasValue = val && val.length > 0
  return (
    <>
      {/* <pre>{JSON.stringify(file || "", null, 4)}</pre> */}
      <FieldLabel isSmall={hasValue} label={props.label} />
      <input
        {...getInputProps(props)}
        data-small={hasValue}
        onAnimationStart={handleAutoFill}
      />
      {error ? <ErrorMessage error={error} /> : null}
    </>
  )
}

function CountryField(props) {
  const {
    meta: { error },
    getInputProps,
  } = useField("country")

  const val = getInputProps().value
  const hasValue = val && val.length > 0
  console.log(val)

  return (
    <>
      <FieldLabel isSmall={true} label={props.label} />
      <select
        {...getInputProps(props)}
        data-small={hasValue}
        onAnimationStart={handleAutoFill}
      >
        {countryList.map((country, i) => (
          <option key={i} value={country}>
            {country}
          </option>
        ))}
      </select>
      {error ? <ErrorMessage error={error} /> : null}
    </>
  )
}

function BotField(props) {
  const { getInputProps } = useField("bot-field")

  return <input {...getInputProps(props)} name="bot-field" />
}

export default function Form(props) {
  // Use the useForm hook to create a form instance
  const [reward, setReward] = useState(null)

  const {
    Form,
    meta: { isSubmitted, isSubmitting },
  } = useForm({
    onSubmit: async values => {
      values["form-name"] = "New Careers Form"
      values["job_title"] = props.jobTitle || " "

      if (file) values["file"] = file

      const res = await sendToServer(values)
      res && reward.rewardMe() // Confetti

      if (res === true && window.dataLayer !== "undefined") {
        window.dataLayer.push({
          event: "Form Submission",
          formName: "Careers Form",
          category: "New Applicant",
          action: "Form Submitted",
          jobTitle: props.jobTitle,
        })
      }
    },
    debugForm: false,
  })

  const [file, setFile] = useState(null)

  return (
    <section>
      <Form
        name="New Careers Form"
        data-netlify="true"
        className="mx-auto c-form c-form--primary c-form--careers"
        disabled={isSubmitted}
        autoComplete={`no`}
      >
        <input type="hidden" name="form-name" value="New Careers Form" />

        <label hidden>
          <BotField />
        </label>

        <label className="mb-2 c-form__label">
          <NameField
            className="c-form__field c-form__field--input"
            label="Your name"
          />
        </label>

        <label className="mb-2 c-form__label">
          <EmailField
            className="c-form__field c-form__field--input"
            label="Your email"
          />
        </label>

        <label className="mb-2 c-form__label">
          <LocationField
            className="c-form__field c-form__field--input"
            label="Your location"
          />
        </label>

        <label className="c-form__label">
          <PortfolioField
            className="c-form__field c-form__field--input"
            label="Your portfolio URL"
            file={file}
          />
        </label>

        <div className="relative mt-6">
          <div className="c-form__fileupload">
            <FileUpload file={file} setFile={setFile} />
          </div>
        </div>

        <div className="mt-6 text-center">
          <Reward
            ref={ref => {
              setReward(ref)
            }}
            type="confetti"
            config={{
              spread: 120,
              springAnimation: false,
              colors: ["#00122c", "#01cfc9", "#727C89", "#727C89"],
            }}
          >
            <button
              className={`w-full c-form__field c-form__field--submit relative ${
                isSubmitting ? "is-submitting" : ""
              }`}
              type="submit"
              disabled={isSubmitting || isSubmitted}
            >
              {!isSubmitting ? (
                <>{!isSubmitted ? "Send" : "Sent"}</>
              ) : (
                <div className="absolute inset-0 flex flex-col items-center justify-center pointer-events-none">
                  <Spinner />
                </div>
              )}
            </button>
          </Reward>
        </div>
      </Form>
    </section>
  )
}
